import { Heading } from '../Scl/Typography/Heading'
import { Btn } from '../Scl/Form/Button/Btn'
import { useLocale } from '~/hooks/locale'
import { FunctionComponent } from 'react'
import Image from 'next/image'
import Link from 'next/link'

export interface Props {
  text: string
  link: any[]
}

export const NotFoundSection: FunctionComponent<Props> = ({ text, link }) => {
  const { culture } = useLocale()
  return (
    <div className='flex flex-col items-center'>
      <div className='relative flex items-center justify-center w-32 h-32 mt-8 mb-4'>
        <Image
          layout='fill'
          src='/assets/404.png'
          alt={culture === 'sv' ? 'Illustration av en hund som är förvirrad' : 'Illustration of a dog that is confused'}
        />
      </div>

      <Heading className='mb-2' size="huge">404</Heading>

      <p>{text}</p>

      <Link href={`/${culture}`}>
        <a>
          <Btn theme='link'>
            {link[0].text}
          </Btn>
        </a>
      </Link>
    </div>
  )
}
