import { GlobalNavigation } from 'components/Storyblok/Partials/Global/GlobalNavigation'
import { GlobalOffset } from '~/components/Storyblok/Partials/Global/GlobalOffset'
import { NotFoundSection } from '~/components/Storyblok/NotFoundSection'
import { GlobalSettingPage } from '~/models/GlobalSettingPage'
import { BaseLayout } from 'components/BaseLayout/BaseLayout'
import { GlobalContext } from 'context/GlobalContext'
import { useEffect, useState } from 'react'
import { getStory } from 'utils/storyblok'
import { useLocale } from '~/hooks/locale'
import { Story } from '@storyblok/react'
import { NextPage } from 'next'

const Custom404Page: NextPage = () => {
  const [data, setData] = useState<Story<GlobalSettingPage> | undefined>()
  const { culture } = useLocale()

  useEffect(() => {
    async function fetchData() {
      const data = await getStory([culture, 'settings'])
      setData(data)
    }
    fetchData()
  }, [culture])

  if(!data) {
    return null
  }

  return (
    <BaseLayout>
      <GlobalContext.Provider value={{
        cookieConcent: data.content.cookieConsent && data.content.cookieConsent[0],
        blogFooter: data.content.blogFooter[0],
        navigation: data.content.navigation[0],
        footer: data.content.footer[0],
      }}>
        <GlobalNavigation />

        <main className="min-h-screen">
          <GlobalOffset
            className="pt-26 lg:pt-32 xl:pt-22"
            theme="GRAY"
          />

          <NotFoundSection {...data.content.notFound[0]} />
        </main>
      </GlobalContext.Provider>
    </BaseLayout>
  )
}

export default Custom404Page
